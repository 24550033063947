import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (globaltheme: any) => {
    return {
      wrapperClassName: {
        width: ({ fullWidth }: any) => fullWidth && '100%',
      },
      inputBorder: {
        border: `2px solid ${globaltheme?.palette?.secondary?.shade_200}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: '0 14px',
        '&:hover': {
          background: globaltheme?.palette?.secondary?.shade_50,
          border: `2px solid ${globaltheme?.palette?.secondary?.shade_200}`,
        },
        '&:focus': {
          background: globaltheme?.palette?.basic?.white,
          border: `2px solid ${globaltheme?.palette?.primary?.main}`,
        },
      },
      calendarWrapper: {
        background: globaltheme?.palette?.basic?.main,
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.12)',
        borderRadius: '3px',
        border: 0,
        fontFamily: globaltheme?.typography?.fontFamily,
        '& .react-datepicker__month': {
          padding: '0 16px 16px',
          margin: '0',
        },
        '& .react-datepicker__header': {
          background: globaltheme?.palette?.basic?.main,
          border: 0,
          padding: 0,
          color: globaltheme?.palette?.secondary?.main,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
        },
        '& .react-datepicker__header .react-datepicker__day-name': {
          fontSize: '11px',
          fontWeight: 300,
          letterSpacing: '0px',
          textAlign: 'center',
          width: '41px',
          lineHeight: '16px',
          margin: '8px 0',
          color: globaltheme?.palette?.secondary?.shade_700,
          textTransform: 'uppercase',
        },
        '& .react-datepicker__header .react-datepicker__current-month': {
          margin: '20px 0 17px',
        },
        '& .react-datepicker-year-header': {
          margin: '20px 0 17px',
        },
        '& .react-datepicker__navigation': {
          textIndent: 'unset',
          width: '24px',
          height: '24px',
          overflow: 'visible',
          background: globaltheme?.palette?.basic?.main,
          border: 'none',
          top: '16px',
        },
        '& .react-datepicker__navigation--previous': {
          left: '24px',
        },
        '& .react-datepicker__navigation--next': {
          right: '24px',
        },
        '& .react-datepicker__month-text': {
          fontSize: '14px',
          fontWeight: 400,
          letterSpacing: '0px',
          textAlign: 'center',
          width: '84px',
          lineHeight: '32px',
          margin: '0 12px 16px 0',
          borderRadius: '3px',
          position: 'relative',
          boxSizing: 'border-box',
          '&:hover': {
            background: globaltheme?.palette?.primary?.shade_100,
          },
          '&.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected':
            {
              background: globaltheme?.palette?.primary?.main,
              color: globaltheme?.palette?.basic?.main,
              fontWeight: 400,
            },
          '&.react-datepicker__month-text--keyboard-selected': {
            background: globaltheme?.palette?.basic?.main,
            color: globaltheme?.palette?.secondary?.main,
          },
        },
        '& .react-datepicker__day--in-range, & .react-datepicker__day--in-selecting-range':
          {
            backgroundColor: globaltheme?.palette?.primary?.main,
            borderRadius: 0,
            color: globaltheme?.palette?.basic?.main,
          },
        '& .react-datepicker__day--highlighted': {
          backgroundColor: globaltheme?.palette?.primary?.main,
          borderRadius: 0,
          color: globaltheme?.palette?.basic?.main,
        },
        // '& .react-datepicker__navigation-icon:before': {
        //   content: none;
        // }
        // react-datepicker__day--in-range react-datepicker__day--weekend
      },
      day: {
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0px',
        textAlign: 'center',
        width: '41px',
        lineHeight: '32px',
        margin: 0,
        borderRadius: '3px',
        position: 'relative',
        boxSizing: 'border-box',
        '&:hover': {
          background: globaltheme?.palette?.primary?.shade_100,
        },
      },
      today: {
        color: globaltheme?.palette?.primary?.main,
        fontWeight: 400,
        border: `2px solid ${globaltheme?.palette?.primary?.main}`,
        lineHeight: '28px',
        '&:after': {
          content: '""',
          position: 'absolute',
          left: '2px',
          bottom: '2px',
          height: '2px',
          width: 'calc(100% - 4px)',
          background: globaltheme?.palette?.primary?.main,
        },
      },
      selectedDay: {
        background: globaltheme?.palette?.primary?.main,
        color: globaltheme?.palette?.basic?.main,
        fontWeight: 400,
      },
      monthClickTransperentOverlay: {
        position: 'absolute',
        height: '24px',
        width: 'calc(100% - 96px)',
        background: 'transparent',
        top: '16px',
        right: '48px',
      },
      icon: {
        color: globaltheme?.palette?.secondary?.main,
      },
      colorMain: {
        color: `${globaltheme?.palette?.secondary?.main} !important`,
      },
    };
  },
  { name: 'ComponentLibrary' }
);
