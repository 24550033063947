import React from 'react';
import classes from "./RecordLabel.module.css";
import classnames from 'classnames';
import { formatNumbeWithCommasString, pluralise } from '../../../../utils/utils';

interface RecordLabelProps {
  page: number;
  perPage: number;
  total: number;
  entityName: string;
  recordLabelCustomClassName?: string; // optional prop
}
const RecordsLabel = (props: RecordLabelProps) => {
    const { page, perPage, total, entityName, recordLabelCustomClassName } = props;
    const from = (page - 1) * perPage + 1;
  const to = Math.min(page * perPage, total);
  return (
    <div className={classnames(classes.recordLabelStyle,recordLabelCustomClassName)}>
            Showing
            <span className={classes.bold}>{` ${from}-${to}`}</span>
            {' of '}
            <span className={classes.bold}>
              {formatNumbeWithCommasString(total)}
            </span>
            &nbsp;{pluralise(entityName,total)}
          </div>
  )
}

export default RecordsLabel;
