import React, { useState } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';

import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import withGenerateClassName from '../../../themes/withGenerateClassName';
import Input, { InputProps } from '../Input/Input';
import useInputStyles from '../Input/Input.styles';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from './DataPicker.styles';

import { format } from 'date-fns';
interface DatePickerProps {
  value: any;
  onChange: any;
  className?: string;
  size: 'thin' | 'small';
  fullWidth?: boolean;
  selectsRange?: boolean;
  multipleSelect?: boolean;
  endDate?: any;
  startDate?: any;
  onCalendarOpen?: any;
  icon?: React.ReactNode;
  placeholder?: string;
  inputProps?: InputProps;
  error?: boolean;
  helperText?: string;
  calendarOnly?: boolean;
  onMultipleSelect?: Function;
  dateFormat?: string;
  wrapperClassName?: string;
  calendarWrapper?: string;
}

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate?.getDate() == today?.getDate() &&
    someDate?.getMonth() == today?.getMonth() &&
    someDate?.getFullYear() == today?.getFullYear()
  );
};

const isSelected = (selectedDate: Date, someDate: Date) => {
  return (
    someDate?.getDate() == selectedDate?.getDate?.() &&
    someDate?.getMonth() == selectedDate?.getMonth?.() &&
    someDate?.getFullYear() == selectedDate?.getFullYear?.()
  );
};

const ExlyDatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    size = 'small',
    value,
    onChange,
    fullWidth,
    className,
    selectsRange,
    onCalendarOpen,
    icon,
    multipleSelect,
    placeholder,
    inputProps = {},
    error,
    helperText,
    calendarOnly,
    onMultipleSelect,
    dateFormat = 'dd/MM/yyyy',
    wrapperClassName,
    calendarWrapper,
    ...rest
  } = props;
  const inputClasses = useInputStyles({ fullWidth });
  const classes = useStyles({ fullWidth });
  const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
  const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(true);

  const handleChange = (date: Date) => {
    if (showMonthYearPicker) {
      setShowMonthYearPicker(false);
      setShouldCloseOnSelect(true);
    }
    if (selectsRange && Array.isArray(date) && !date[1]) {
      setShouldCloseOnSelect(true);
    }
    onChange?.(date);
  };

  const handleMonthClick = () => {
    setShowMonthYearPicker(true);
    setShouldCloseOnSelect(false);
  };

  const handleCalendarOpen = () => {
    selectsRange && setShouldCloseOnSelect(false);
    onCalendarOpen && onCalendarOpen();
  };

  const handleMultipleSelect = (date: Date) => {
    let index = value.findIndex(
      (item: Date) => item.toDateString() === date.toDateString()
    );

    let dates: Date[] = [...value];

    if (index !== -1) {
      dates.splice(index, 1);
    } else dates.push(date);

    onMultipleSelect?.(dates);
  };

  const { inputClassName, ...restInputProps } = inputProps as InputProps;

  const CustomInput = ({ value, selectedDates, onClick }: any) => {
    const { startDate, endDate } = rest;

    let inputValue: string = value;
    let defaultPlaceholder = selectsRange
      ? dateFormat
        ? `${dateFormat} ~ ${dateFormat}`
        : 'dd/mm/yyyy ~ dd/mm/yyyy'
      : dateFormat || 'dd/mm/yyyy';

    if (multipleSelect) {
      inputValue = selectedDates
        .map((date: Date) => format(date, dateFormat))
        .join(', ');
      defaultPlaceholder = 'Select dates';
    }

    if (selectsRange && startDate)
      inputValue = `${
        (startDate && format(startDate, dateFormat)) || dateFormat
      } ~ ${(endDate && format(endDate, dateFormat)) || dateFormat}`;

    return (
      <Input
        placeholder={placeholder || defaultPlaceholder}
        value={inputValue}
        onClick={onClick}
        size={size}
        icon={icon || <DateRangeIcon className={classes.icon} />}
        iconPosition="end"
        fullWidth={fullWidth}
        error={error}
        helperText={helperText}
        disabled={calendarOnly}
        inputClassName={classnames(
          calendarOnly && classes.colorMain,
          inputClassName
        )}
        {...restInputProps}
      />
    );
  };

  return (
    <DatePicker
      dateFormat={dateFormat}
      selected={multipleSelect ? null : value}
      customInput={
        <CustomInput {...(multipleSelect && { selectedDates: value })} />
      }
      onChange={(date: Date) => handleChange(date)}
      showPopperArrow={false}
      wrapperClassName={classnames(classes.wrapperClassName, wrapperClassName)}
      className={classnames(
        classes.inputBorder,
        inputClasses.input,
        size && inputClasses[size],
        className
      )}
      disabledKeyboardNavigation
      showFullMonthYearPicker
      showMonthYearPicker={showMonthYearPicker}
      shouldCloseOnSelect={shouldCloseOnSelect}
      selectsRange={selectsRange}
      calendarClassName={classnames(classes.calendarWrapper, calendarWrapper)}
      onCalendarOpen={handleCalendarOpen}
      // onCalendarClose={handleCalendarClose}
      // monthClassName
      dayClassName={(date) => {
        if (isSelected(value, date) && !multipleSelect) {
          return classnames(classes.day, classes.selectedDay);
        }
        if (isToday(date)) {
          return classnames(classes.day, classes.today);
        }
        return classes.day;
      }}
      previousMonthButtonLabel={<ChevronLeftIcon />}
      previousYearButtonLabel={<ChevronLeftIcon />}
      nextMonthButtonLabel={<ChevronRightIcon />}
      nextYearButtonLabel={<ChevronRightIcon />}
      {...(multipleSelect && {
        onSelect: handleMultipleSelect,
        highlightDates: value,
        shouldCloseOnSelect: false,
      })}
      {...rest}
    >
      <div
        onClick={handleMonthClick}
        className={classes.monthClickTransperentOverlay}
      />
    </DatePicker>
  );
};

export default withGenerateClassName(ExlyDatePicker);
