import MuiChip, { ChipProps as MuiChipProps } from '@material-ui/core/Chip';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { FC } from 'react';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import { useStyles } from './Chip.styles';

interface ChipProps extends MuiChipProps {
  chipIcon?: React.ReactElement;
  chipClassName?: string;
  labelClassName?: string;
  deleteIconClassName?: string;
  error?: boolean;
}

const Chip: FC<ChipProps> = ({
  chipIcon = <ClearRoundedIcon />,
  chipClassName,
  labelClassName,
  deleteIconClassName,
  error = false,
  ...restProps
}) => {
  const classes = useStyles(error);

  return (
    <MuiChip
      deleteIcon={<div>{chipIcon}</div>}
      classes={{
        root: [classes.root, error && classes.error, chipClassName].join(' '),
        label: [classes.label, labelClassName].join(' '),
        deleteIcon: [classes.deleteIcon, deleteIconClassName].join(' '),
      }}
      {...restProps}
    />
  );
};

export default withGenerateClassName(Chip);
