import { FC, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PreviousIcon from '@material-ui/icons/ArrowBackIosRounded';
import NextIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ForwardIcon from '@material-ui/icons/DoubleArrowRounded';
import JumpIcon from '@material-ui/icons/MoreHorizRounded';

import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import { DOTS, usePagination } from './usePagination';
import withGenerateClassName from '../../../themes/withGenerateClassName';
import RecordsLabel from './RecordsLabel/RecordsLabel';
import cssStyles from "./DesktopPagination.module.css";

const useStyles = makeStyles(
  (globalTheme: any) => ({
    paginationRoot: {
      display: 'flex',
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      color: 'rgba(0, 0, 0, 0.65)',
      fontSize: '14px',
      fontWeight: 500,
      listStyle: 'none',
      fontFamily: globalTheme?.typography?.fontFamily,
    },
    pageRoot: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '32px',
      height: '32px',
      marginRight: '8px',
      backgroundColor: globalTheme?.palette?.basic?.white,
      border: `1px solid ${globalTheme?.palette?.secondary?.shade_100}`,
      borderRadius: '2px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: globalTheme?.palette?.primary?.main,
        color: globalTheme?.palette?.primary?.main,
      },
      transition: 'all 0.3s',
      '&:last-child': {
        marginRight: 0,
      },
    },
    actionButtonIcon: {
      fontSize: '13px',
    },
    jumpIcon: {
      border: 'none !important',
      color: `${globalTheme?.palette?.secondary?.shade_100} !important`,
      transition: 'none',
    },
    active: {
      borderColor: globalTheme?.palette?.primary?.main,
      color: globalTheme?.palette?.primary?.main,
    },
    disabled: {
      pointerEvents: 'none',
    },
    backward: {
      transform: 'scaleX(-1)',
      color: globalTheme?.palette?.primary?.main,
    },
    forward: {
      color: globalTheme?.palette?.primary?.main,
    },
    inputBaseRootClassName: {
      border: `1px solid ${globalTheme?.palette?.secondary?.shade_100}`,
      borderRadius: '2px',
      height: '32px',
    },
    labelClassName: {
      display: 'none',
    },
    inputClassName: {
      height: '30px',
      width: '69px',
      fontSize: '14px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.65)',
    },
    inputWrapperClassName: {
      border: `1px solid ${globalTheme?.palette?.secondary?.shade_100}`,
      borderRadius: '2px',
      marginLeft: '8px',
      '& fieldset': {
        border: 'none !important',
      },
    },
    simpleItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '32px',
      height: '32px',
      marginLeft: '8px',
      fontSize: '14px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.65)',
    },
    selectClassName: {
      fontSize: '14px',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.65)',
    },
  }),
  { name: 'ComponentLibrary' }
);

export interface Option {
  id: string;
  label?: string;
  value: string;
}

export interface DesktopPaginationProps {
  total: number;
  siblingCount: number;
  page: number;
  perPage: number;
  perPageOptions?: Option[];
  setPage?: Function;
  setPerPage?: Function;
  goToPage?: boolean;
  paginationClassName?: string;
  paginationDropdownClassName?: string;
  paginationGoToInputClassName?: string;
  entityName?: string;
  recordLabelCustomClassName?: string;
}

const DesktopPagination: FC<DesktopPaginationProps> = ({
  setPage,
  total = 1,
  siblingCount = 2,
  page = 1,
  perPage = 1,
  perPageOptions,
  setPerPage,
  goToPage,
  paginationClassName,
  entityName = "item",
  recordLabelCustomClassName,
  ...restProps
}) => {
  const classes = useStyles();

  const paginationRange: any = usePagination({
    page: isNaN(page) ? 1 : page,
    total: isNaN(total) ? 1 : total,
    siblingCount,
    perPage: isNaN(perPage) ? 1 : perPage,
  });

  const onNext = () => {
    setPage?.(page + 1);
  };

  const onPrevious = () => {
    setPage?.(page - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  const DotsIcon = ({ index }: { index: number }) => {
    const [toggle, setToggle] = useState(true);

    return toggle ? (
      <JumpIcon onMouseEnter={() => setToggle(false)} />
    ) : (
      <ForwardIcon
        className={index > 1 ? classes.forward : classes.backward}
        onMouseLeave={() => setToggle(true)}
      />
    );
  };

  const totalPageCount = Math.ceil(total / perPage);

  return (
    <div className={cssStyles.wrapper}>
      <RecordsLabel page={page} perPage={perPage} total={total} entityName={entityName} recordLabelCustomClassName={recordLabelCustomClassName}/>
          <ul
      className={[classes.paginationRoot, paginationClassName].join(' ')}
      {...restProps}
    >
      <li
        className={[classes.pageRoot, page === 1 && classes.disabled].join(' ')}
        onClick={onPrevious}
      >
        <PreviousIcon className={classes.actionButtonIcon} />
      </li>

      {paginationRange.map((pageNumber: number | string, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={Math.random()}
              className={[classes.pageRoot, classes.jumpIcon].join(' ')}
              onClick={() =>
                setPage?.(
                  index > 1
                    ? Math.min(page + 5, totalPageCount)
                    : Math.max(page - 5, 1)
                )
              }
            >
              <DotsIcon index={index} />
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={[
              classes.pageRoot,
              pageNumber === page && classes.active,
            ].join(' ')}
            onClick={() => setPage?.(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        className={[
          classes.pageRoot,
          page === lastPage && classes.disabled,
        ].join(' ')}
        onClick={onNext}
      >
        <NextIcon className={classes.actionButtonIcon} />
      </li>

      {perPageOptions && (
        <li>
          <Dropdown
            inputBaseRootClassName={classes.inputBaseRootClassName}
            value={String(perPage)}
            handleChange={(value) => setPerPage?.(value)}
            options={perPageOptions}
            width="106px"
            selectClassName={classes.selectClassName}
          />
        </li>
      )}
      {goToPage && (
        <>
          <li
            className={classes.simpleItem}
            style={{ marginLeft: perPageOptions ? '8px' : 0 }}
          >
            Go to
          </li>
          <li>
            <Input
              inputProps={{ min: 1, max: totalPageCount }}
              value={page}
              labelClassName={classes.labelClassName}
              inputClassName={classes.inputClassName}
              wrapperClassName={classes.inputWrapperClassName}
              type="number"
              onChange={(event) =>
                setPage?.(Number.parseInt(event.target.value))
              }
            />
          </li>
          <li className={classes.simpleItem}>Page</li>
        </>
      )}
    </ul>
    </div>
  );
};

export default withGenerateClassName(DesktopPagination);
