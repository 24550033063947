import { makeStyles } from '@material-ui/core/styles';

interface StyleProps {
  tall?: boolean;
  error?: boolean;
  grouped?: boolean;
  width?: string;
  paperWidth?: string;
  minWidth?: string;
  showCrossIcon?: boolean;
  size?: 'thin' | 'small';
  dropdownLabel?: any;
}

const useStyles = makeStyles(
  (theme: any) => ({
    formControl: {
      position: 'relative',
      width: ({ width }: StyleProps) => width,
    },
    customInputBaseRoot: {
      width: ({ width }: StyleProps) => width,
      minWidth: ({ minWidth }: StyleProps) => minWidth,
      boxSizing: 'border-box',
      overflow: 'hidden',
      fontSize: '14px',
      borderRadius: '5px',
      color: theme?.palette?.secondary?.main,
      background: theme?.palette?.basic?.white,
      border: ({ error }: StyleProps) =>
        `1.5px solid ${
          error
            ? theme?.palette?.basic?.danger_red
            : theme?.palette?.secondary?.shade_200
        }`,
      '&.Mui-focused': {
        border: `1.5px solid ${theme?.palette?.primary?.main}`,
      },
      '&.Mui-disabled': {
        border: `1.5px solid ${theme?.palette?.secondary?.main}`,
        opacity: '0.5',
      },
    },
    selectIcon: {
      right: '6px',
    },
    ellipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: ({ size }) => (size === 'small' ? '16px' : '14px'),
    },
    select: {
      padding: ({ showCrossIcon }: StyleProps) =>
        theme.spacing(3, showCrossIcon ? 8 : 6, 3, 4),
      marginRight: ({ showCrossIcon }: StyleProps) =>
        showCrossIcon ? '24px' : '',
      color: theme?.palette?.secondary?.main,
      background: theme?.palette?.basic?.white,
      display: 'flex',
      alignItems: 'center',
      lineHeight: ({ size }) => (size === 'thin' ? '13px' : '21px'),
      boxSizing: 'border-box',
      '&:focus': {
        background: theme?.palette?.basic?.white,
      },
    },
    paper: {
      boxShadow: '1px 2px 4px 4px rgba(0, 0, 0, 0.12)',
      marginTop: '7px',
      borderRadius: '3px',
      width: ({ paperWidth }: StyleProps) => paperWidth,
      '&::-webkit-scrollbar': {
        width: '3px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme?.palette?.secondary?.shade_100,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.palette?.secondary?.shade_400,
        borderRadius: theme.spacing(1),
      },
    },
    list: {
      paddingTop: '0',
      paddingBottom: '0',
      maxHeight: ({ tall }: StyleProps) => (tall ? '195px' : ''),
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme?.palette?.secondary?.shade_100,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.palette?.secondary?.shade_400,
        borderRadius: '12px',
      },
      '& li': {
        fontSize: '14px',
        minHeight: '26px',
        boxSizing: 'border-box',
      },
    },
    placeholder: {
      color: theme?.palette?.basic?.light_gray,
    },
    defaultMenuItem: {
      color: theme?.palette?.secondary?.main,
      display: 'flex',
      gap: '8.5px',
      alignItems: 'center',
      whiteSpace: 'break-spaces',
      padding: ({ grouped }: StyleProps) => (grouped ? '5px 18px' : '5px 9px'),
      '&.Mui-selected:not(.Mui-disabled)': {
        background: theme?.palette?.primary?.main,
        color: theme?.palette?.basic?.white,
        '& svg': {
          color: theme?.palette?.basic?.white,
        },
      },
      '&:hover:not(.Mui-disabled)': {
        background: theme?.palette?.secondary?.shade_100,
        color: theme?.palette?.secondary?.main,
        '& svg': {
          color: theme?.palette?.secondary?.main,
        },
      },
    },
    menuItemLeftIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: '15px',
        height: '15px',
      },
    },
    defaultGroupHeading: {
      background: theme?.palette?.basic?.white,
      color: theme?.palette?.secondary?.shade_700,
      padding: '5px 10px',
      fontSize: '14px',
      height: '26px',
      boxSizing: 'border-box',
      opacity: '1 !important',
      '&.Mui-disabled': {
        color: theme?.palette?.secondary?.shade_700,
      },
    },
    checkedMenuItem: {
      color: theme?.palette?.secondary?.main,
      background: theme?.palette?.basic?.white,
      paddingLeft: '11px',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      gap: '10px',
      // '& svg, & .MuiButtonBase-root': {
      //   width: theme.spacing(4),
      //   height: theme.spacing(4),
      //   padding: theme.spacing(0),
      // },
      '& svg': {
        width: theme.spacing(4),
        height: theme.spacing(4),
        padding: theme.spacing(0),
      },
      '&.Mui-selected:not(.Mui-disabled), &:hover': {
        background: theme?.palette?.basic?.white,
      },
    },
    checkboxRoot: {
      width: '12px !important',
      height: '12px !important',
      padding: theme.spacing(0),
    },
    checkItemLabel: {
      fontSize: '14px',
    },
    simpleMenuItem: {},
    helperTextWrapper: {
      margin: '10px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      color: ({ error }) =>
        error
          ? theme?.palette?.basic?.danger_red
          : theme?.palette?.secondary?.main,
      fontSize: theme.spacing(4),
      lineHeight: '16px',
      width: ({ width }: StyleProps) => width,
    },
    warningIcon: {
      width: '13px',
      height: '11px',
    },
    circularProgressWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: theme.spacing(10),
      overflow: 'hidden',
    },
    circularProgressRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circularProgressSvg: {
      color: theme?.palette?.secondary?.main,
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '5px',
      width: '100%',
    },
    filterChip: {
      maxWidth: '100%',
      height: 'auto !important',
    },
    deleteAllChipsBtn: {
      position: 'absolute',
      right: '28px',
      top: ({ dropdownLabel }) => (dropdownLabel ? 'calc(50% + 13px)' : '50%'),
      transform: 'translateY(-50%)',
      padding: '0',
      zIndex: 10,
      '&:hover': {
        background: theme?.palette?.basic?.white,
      },
      '& svg': {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    dropdownLabel: {
      fontSize: '14px',
      marginBottom: '8px',
      fontFamily: theme?.typography?.fontFamily,
    },
    semi_bold: {
      fontWeight: 500,
    },
    fontSizeMedium: {
      fontSize: '16px',
    },
    cta: {
      position: 'sticky',
      bottom: 0,
    },
    ctaBtn: {
      borderRadius: '0 0 4px 4px',
    },
  }),
  { name: 'ComponentLibrary' }
);

export default useStyles;
