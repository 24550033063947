import React, { useState, useRef, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';

import useStyles from './Dropdown.styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import Chip from '../Chip/Chip';
import withGenerateClassName from '../../../themes/withGenerateClassName';

export interface IDropdownLabel {
  label: string | React.ReactNode;
  weight?: 'regular' | 'semi-bold';
  size?: 'small' | 'medium';
  className?: string;
}

export interface Option {
  id: string;
  label?: string;
  value: string;
  icon?: any;
  disabled?: boolean;
}

interface GroupedOption {
  id: string;
  name: string;
  suboptions: Option[];
}

export interface IDropdown extends Omit<SelectProps, 'placeholder'> {
  options?: Option[];
  groupedOptions?: GroupedOption[];
  placeholder?: React.ReactNode;
  error?: boolean;
  loading?: boolean;
  tall?: boolean;
  helperText?: string;
  grouped?: boolean;
  checked?: boolean;
  showChips?: boolean;
  includesCta?: boolean;
  showSelectAll?: boolean;
  handleChange?: (a: string | string[]) => void;
  width?: string;
  fullWidth?: boolean;
  minWidth?: string;
  customSelectAllText?: string;
  customDeSelectAllText?: string;
  selectClassName?: string;
  formControlClassName?: string;
  menuItemIconClassName?: string;
  inputBaseRootClassName?: string;
  dropdownLabel?: IDropdownLabel;
  helperTextClassName?: string;
  size?: 'thin' | 'small';
  selectClasses?: any;
  restMenuProps?: any;
  renderMenuListHeader?: (onClose: any) => React.ReactNode;
  renderMenuListFooter?: (onClose: any) => React.ReactNode;
  allOptionsSelectedLabel?: string;
  menuItemClassName?: string;
  groupLabelClassName?: string;
  showChipsCrossIcon?: boolean;
  deleteAllChipsBtnClassName?: string;
}

const Dropdown: React.FC<IDropdown> = ({
  options = [],
  groupedOptions = [],
  placeholder,
  loading,
  error,
  tall,
  helperText,
  grouped,
  checked,
  showChips,
  includesCta,
  showSelectAll,
  handleChange,
  width,
  minWidth,
  customSelectAllText,
  customDeSelectAllText,
  selectClassName,
  formControlClassName,
  inputBaseRootClassName,
  menuItemIconClassName,
  dropdownLabel,
  fullWidth,
  helperTextClassName,
  size = 'thin',
  selectClasses = {},
  restMenuProps = {},
  multiple,
  value,
  renderMenuListHeader,
  renderMenuListFooter,
  allOptionsSelectedLabel = 'All from the list',
  menuItemClassName,
  groupLabelClassName,
  showChipsCrossIcon = true,
  deleteAllChipsBtnClassName,
  ...restProps
}) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useRef(null);
  const [paperWidth, setPaperWidth] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let handleApply = () => {};

  const showCrossIcon =
    showChipsCrossIcon &&
    multiple &&
    showChips &&
    (value as string[]).length > 0;
  const classes = useStyles({
    error,
    tall,
    grouped,
    width: fullWidth ? '100%' : width,
    minWidth,
    showCrossIcon,
    paperWidth: fullWidth ? paperWidth : width,
    size,
    dropdownLabel,
  });

  useEffect(() => {
    if (includesCta) setSelected(value);
  }, [value]);

  function handlePaperResize() {
    if (!selectRef.current) return;
    const selectRefWidth = selectRef.current.offsetWidth;
    setPaperWidth(selectRefWidth);
  }

  useEffect(() => {
    handlePaperResize();
    window.addEventListener('resize', handlePaperResize);
    return () => {
      window.removeEventListener('resize', handlePaperResize);
    };
  }, []);

  const optionsLabelMap = new Map();
  const groupedOptionsLabelMap = new Map();

  options.forEach((option) => optionsLabelMap.set(option.value, option.label));
  groupedOptions.forEach((groupOption) => {
    groupOption.suboptions.forEach((subOption) => {
      groupedOptionsLabelMap.set(subOption.value, subOption.label);
    });
  });

  let isAllSelected;
  let isIndeterminate;
  if (multiple && options.length) {
    isAllSelected = includesCta
      ? selected.length === options.length
      : (value as string[]).length === options.length;
    let valueArr = includesCta ? selected : (value as string[]);
    isIndeterminate = valueArr.length > 0 && valueArr.length < options.length;
  }

  const { menu_paper_classname, ...rest_menu_props } = restMenuProps;

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classnames(classes.paper, menu_paper_classname),
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
    ...rest_menu_props,
  };

  let additionalProps = {};

  const customRenderValue = (selectedValue: string[]) => {
    if (selectedValue.length === 0)
      return (
        <div className={classnames(classes.ellipsis, classes.placeholder)}>
          {placeholder}
        </div>
      );
    if (showSelectAll && selectedValue.length === options.length)
      return allOptionsSelectedLabel;

    const selectedLabels = selectedValue.map((_val) => {
      return grouped
        ? groupedOptionsLabelMap.get(_val)
        : optionsLabelMap.get(_val);
    });

    if (!showChips) {
      const selectedListValue = selectedLabels.join(', ');
      return (
        <Box title={selectedListValue} className={classes.ellipsis}>
          {selectedListValue}
        </Box>
      );
    }
    const onDelete = (e: React.MouseEvent, chipValue: string) => {
      e.preventDefault();
      const updatedValue = selectedValue.filter((val) => val !== chipValue);
      handleChange?.(updatedValue);
    };
    return (
      <div className={classes.chips}>
        {selectedValue.map((_value) => (
          <Chip
            key={_value}
            label={
              grouped
                ? groupedOptionsLabelMap.get(_value)
                : optionsLabelMap.get(_value)
            }
            chipIcon={
              <ClearRoundedIcon
                onMouseDown={(event) => event.stopPropagation()}
              />
            }
            chipClassName={classes.filterChip}
            onDelete={(e) => onDelete(e, _value)}
          />
        ))}
      </div>
    );
  };

  if (multiple) {
    additionalProps = {
      renderValue: customRenderValue,
      onChange: (event: React.ChangeEvent<{ value: unknown }>) => {
        const currentSelection = event.target.value as string[];
        if (!handleChange) return;
        if (currentSelection[currentSelection.length - 1] === 'all') {
          const updatedValue =
            (value as string[]).length === options.length
              ? []
              : options.map((option) => option.value);
          handleChange(updatedValue);
          return;
        }
        handleChange(currentSelection);
      },
    };
  }

  if (includesCta) {
    handleApply = () => {
      handleChange?.(selected);
      handleClose();
    };
    additionalProps = {
      open,
      onOpen: handleOpen,
      value: selected,
      onChange: (event: React.ChangeEvent<{ value: unknown }>) => {
        const targetValue = event.target.value as string[];
        const currentSelection = targetValue?.filter(Boolean);

        if (
          currentSelection?.length &&
          currentSelection[currentSelection.length - 1] === 'all'
        ) {
          const updatedValue =
            selected.length === options.length
              ? []
              : options.map((option) => option.value);
          setSelected(updatedValue);
          return;
        }
        setSelected(currentSelection);
      },
      onClose: () => {
        setSelected(value as string[]);
        handleClose();
      },
      renderValue: customRenderValue,
    };
  }

  const renderSelectGroup = (groupedOption: GroupedOption) => {
    let items;
    if (checked) {
      items = groupedOption.suboptions.map((suboption) => (
        <MenuItem
          key={suboption.id}
          value={suboption.value}
          classes={{
            root: classnames(classes.checkedMenuItem, menuItemClassName),
          }}
          disabled={suboption.disabled}
        >
          <Checkbox
            size="medium"
            checked={(value as string[]).indexOf(suboption.value) > -1}
            checkboxClassName={classes.checkboxRoot}
          />
          <span>{suboption.label}</span>
        </MenuItem>
      ));
    } else {
      items = groupedOption.suboptions.map((suboption) => (
        <MenuItem
          key={suboption.id}
          value={suboption.value}
          classes={{
            root: classnames(classes.defaultMenuItem, menuItemClassName),
          }}
          disabled={suboption.disabled}
        >
          {suboption.label}
        </MenuItem>
      ));
    }

    return [
      <Button
        disabled
        color={null}
        size={null}
        className={classnames(classes.defaultGroupHeading, groupLabelClassName)}
      >
        {groupedOption.name}
      </Button>,
      items,
    ];
  };

  const getSelectChildren = (grouped?: boolean, checked?: boolean) => {
    let selectType = '';
    if (grouped) selectType += 'Grouped';
    if (checked) selectType += 'Checked';
    if (includesCta) selectType += 'IncludingCTA';

    switch (selectType) {
      case 'Grouped':
      case 'GroupedChecked':
        return groupedOptions.map(renderSelectGroup);
      case 'Checked':
      case 'CheckedIncludingCTA':
        return options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.value}
            classes={{
              root: classnames(classes.checkedMenuItem, menuItemClassName),
            }}
            disabled={option.disabled}
          >
            <Checkbox
              size="medium"
              checkboxClassName={classes.checkboxRoot}
              checked={
                (includesCta ? selected : (value as string[])).indexOf(
                  option.value
                ) > -1
              }
            />
            <span className={classes.checkItemLabel}>{option.label}</span>
          </MenuItem>
        ));
      default:
        return options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.value}
            classes={{
              root: classnames(classes.defaultMenuItem, menuItemClassName),
            }}
            disabled={option.disabled}
          >
            {option.icon && (
              <Box
                className={[
                  classes.menuItemLeftIcon,
                  menuItemIconClassName,
                ].join(' ')}
              >
                {option.icon}
              </Box>
            )}
            {option.label && <Box>{option.label}</Box>}
          </MenuItem>
        ));
    }
  };

  return (
    <>
      <FormControl
        className={[classes.formControl, formControlClassName].join(' ')}
      >
        {showCrossIcon && (
          <IconButton
            disableRipple
            disableFocusRipple
            component="span"
            className={classnames(
              classes.deleteAllChipsBtn,
              deleteAllChipsBtnClassName
            )}
            onClick={() => handleChange?.([])}
          >
            <CancelRoundedIcon />
          </IconButton>
        )}

        {dropdownLabel && (
          <div
            className={classnames(
              classes.dropdownLabel,
              dropdownLabel.size === 'medium' && classes.fontSizeMedium,
              dropdownLabel.weight === 'semi-bold' && classes.semi_bold,
              dropdownLabel.className
            )}
          >
            {dropdownLabel.label}
          </div>
        )}

        <Select
          ref={selectRef}
          disableUnderline
          IconComponent={KeyboardArrowDownIcon}
          onChange={(event) => handleChange?.(event.target.value as string)}
          MenuProps={menuProps}
          classes={{
            select: [classes.select, selectClassName].join(' '),
            icon: classes.selectIcon,
            ...selectClasses,
          }}
          className={classnames(
            classes.customInputBaseRoot,
            inputBaseRootClassName
          )}
          displayEmpty
          renderValue={() => {
            const currentValue = grouped
              ? groupedOptionsLabelMap.get(value)
              : optionsLabelMap.get(value);
            const displayValue = (currentValue || placeholder) as string;
            return (
              <div
                title={displayValue}
                className={classnames(
                  classes.ellipsis,
                  !currentValue && classes.placeholder
                )}
              >
                {displayValue}
              </div>
            );
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          value={value}
          multiple={multiple}
          {...additionalProps}
          {...restProps}
        >
          {renderMenuListHeader ? renderMenuListHeader(handleClose) : null}

          {showSelectAll && multiple && (
            <MenuItem classes={{ root: classes.checkedMenuItem }} value="all">
              <Checkbox
                size="medium"
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                checkboxClassName={classes.checkboxRoot}
              />
              <span className={classes.checkItemLabel}>
                {isAllSelected
                  ? customDeSelectAllText || 'Deselect All'
                  : customSelectAllText || 'Select All'}
              </span>
            </MenuItem>
          )}

          {getSelectChildren(grouped, checked)}

          {renderMenuListFooter ? renderMenuListFooter(handleClose) : null}

          {loading && (
            <Box className={classes.circularProgressWrapper}>
              <CircularProgress
                classes={{
                  root: classes.circularProgressRoot,
                  svg: classes.circularProgressSvg,
                }}
              />
            </Box>
          )}
          {includesCta && (
            <Button
              buttonWrapperClassName={classes.cta}
              className={classes.ctaBtn}
              color="primary"
              fullWidth
              size="thin"
              onClick={handleApply}
            >
              Apply
            </Button>
          )}
        </Select>
      </FormControl>
      {helperText && (
        <FormHelperText
          classes={{
            root: classnames(classes.helperTextWrapper, helperTextClassName),
          }}
        >
          {error && (
            <WarningRoundedIcon
              preserveAspectRatio="none"
              viewBox="3 4 19.06 17.01"
              className={classes.warningIcon}
            />
          )}
          <span>{helperText}</span>
        </FormHelperText>
      )}
    </>
  );
};

export default withGenerateClassName(Dropdown);
