import { makeStyles } from '@material-ui/core';

interface StyleProps {
  error?: boolean;
}

export const useStyles = (error: boolean) =>
  makeStyles(
    (globaltheme: any) => {
      return {
        root: {
          height: '20px',
          borderRadius: '5px',
          background: globaltheme?.palette?.secondary?.shade_100,
          color: globaltheme?.palette?.secondary?.main,
          fontFamily: globaltheme?.typography?.fontFamily,
          '&:hover': {
            background: error
              ? globaltheme?.palette?.basic?.danger_red
              : globaltheme?.palette?.primary?.shade_200,
            '& $label': {
              color: error
                ? globaltheme?.palette?.basic?.white
                : globaltheme?.palette?.primary?.main,
            },
            '& svg': {
              color: error
                ? globaltheme?.palette?.basic?.white
                : globaltheme?.palette?.primary?.main,
            },
          },
          '&:focus': {
            background: error
              ? globaltheme?.palette?.basic?.danger_red
              : globaltheme?.palette?.primary?.shade_200,
            '& $label': {
              color: error
                ? globaltheme?.palette?.basic?.white
                : globaltheme?.palette?.primary?.main,
            },
            '& svg': {
              color: error
                ? globaltheme?.palette?.basic?.white
                : globaltheme?.palette?.primary?.main,
            },
          },
        },
        label: {
          padding: 0,
          fontSize: '14px',
          margin: '2px 6px',
          transitionDuration: '300ms',
        },
        deleteIcon: {
          display: 'flex',
          margin: 0,
          height: 'auto',
          width: 'auto',
          '& svg': {
            height: '18.2px',
            width: '18.2px',
            marginRight: '3.54px',
            marginLeft: '-2.46px',
            color: error
              ? globaltheme?.palette?.basic?.white
              : globaltheme?.palette?.secondary?.main,
            '& path': { transitionDuration: '300ms' },
          },
        },
        error: {
          color: globaltheme?.palette?.basic?.white,
          background: globaltheme?.palette?.coral_red?.shade_600,
        },
      };
    },
    { name: 'ComponentLibrary' }
  )();
